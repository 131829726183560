import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyC3FGFtiBMcfn_1ukpoexnEnMeg3LamfsU",
    authDomain: "parker-eatery.firebaseapp.com",
    projectId: "parker-eatery",
    storageBucket: "parker-eatery.appspot.com",
    messagingSenderId: "564216999738",
    appId: "1:564216999738:web:b72f1a619dde3c818b2a03",
    measurementId: "G-SX47VBPP90"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app)
