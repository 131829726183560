<template>
  <div class="template">
    <main id="main">
      <!-- ======= Hero Section ======= -->
      <section id="hero" class="d-flex flex-column justify-content-center align-items-center" v-if="!res.closed" v-bind:style="{
        'background-image':
          'linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(' +
          require('../assets' + res.hero) +
          ')',
      }">
        <div class="hero-container text-center" data-aos="fade-in">
          <img :src="require('../assets' + res.logo)" :alt="res.name + ' logo'" :title="res.name + ' logo'"
            width="350px;" />
        </div>
      </section>
      <!-- End Hero -->
      <!-- ======= About Section ======= -->
      <section>
        <div class="container">
          <div class="col-12">
            <div class="section-title">
              <h1>
                {{ res.name }}
                <span class="location">{{ res.location }}</span>
              </h1>
              <p>
                {{ res.intro }}
              </p>
            </div>
          </div>
          <div class="col-md-6" v-if="!res.closed">
            <a class="btn btn-primary btn-block" :href="res.orderLink" target="_blank"
              :title="'Order ' + res.name + ' Online'">ORDER ONLINE</a>
          </div>
        </div>
      </section>
      <!-- End About Section -->
      <section id="menu" class="menu" v-if="!res.closed">
        <div class="container">
          <div class="col-12">
            <div class="section-title">
              <h2>our menus</h2>
              <p>
                {{ res.menuText }}
              </p>
            </div>
            <div class="row">
              <div class="col-md-4 btn-list">
                <a class="btn btn-primary btn-block" :href="res.diningMenu" :title="name + ' Dining Menu'"
                  target="_blank">DINING MENU</a>
              </div>
              <div class="col-md-4 btn-list">
                <a class="btn btn-primary btn-block" :href="res.saladMenu" :title="name + ' Salad Menu'"
                  target="_blank">SALAD CARD</a>
              </div>
              <div class="col-md-4 btn-list">
                <a class="btn btn-primary btn-block" :href="res.cocktailMenu" :title="name + ' Cocktail Menu'"
                  target="_blank">COCKTAIL MENU</a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section v-if="res.tour">
        <div class="container">
          <div class="col-sm-12">
            <div class="virtual__tour">
              <div class="section-title">
                <h2>tour {{ res.name }}</h2>
              </div>
              <iframe width="100%" height="640" frameborder="0" allow="xr-spatial-tracking; gyroscope; accelerometer"
                allowfullscreen scrolling="no" :src="res.tour"></iframe>
            </div>
          </div>
        </div>
      </section>
      <section id="contact" class="contact" v-if="!res.closed">
        <div class="container">
          <div class="col-12">
            <div class="section-title">
              <h2>get in touch</h2>
              <p>
                Please contact us with any questions regarding luncheon or
                dinner parties, and other special dining requests.
              </p>
            </div>
          </div>
          <div class="col-12">
            <div class="row">
              <div class="col-lg-5 d-flex align-items-stretch">
                <div class="info">
                  <div class="phone">
                    <b-icon icon="telephone-fill" class="rounded-circle bg-primary p-3" variant="light"></b-icon>
                    <h4>call:</h4>
                    <p>
                      <a :href="'tel:+1' + res.phone" :title="res.name + ' Phone Number'">{{ res.phone }}</a>
                    </p>
                  </div>
                  <div class="address">
                    <b-icon icon="geo-alt-fill" class="rounded-circle bg-primary p-3" variant="light"></b-icon>
                    <h4>location:</h4>
                    <p>
                      {{ res.address }}
                      <br />
                      {{ res.cityState }}
                    </p>
                  </div>
                  <div class="hours">
                    <b-icon icon="clock-fill" class="rounded-circle bg-primary p-3" variant="light"></b-icon>
                    <h4>hours:</h4>
                    <table>
                      <tr v-for="(value, name) in res.hours" v-bind:key="name.id">
                        <td>{{ name }}</td>
                        <td>{{ value }}</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
              <div class="col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch">
                <div class="info">
                  <iframe :src="res.map" frameborder="0" style="border: 0; width: 100%; height: 100%"
                    allowfullscreen=""></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <MoreParker />
      <section id="vip">
        <div class="container">
          <div class="text-center">
            <h5 class="text-white">
              Join parker! <a href="sms:888-972-6177&body=parker" class="light underline">Click here</a> to receive
              member only promotions and specials.
            </h5>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>
<script>
import locations from "@/assets/json/data.json";
import MoreParker from "../components/MoreParker.vue";

export default {
  components: {
    MoreParker
  },
  name: "HomePage",
  props: {},
  data() {
    return {
      res: null,
      Locations: locations,
      isActive: true,
    };
  },
  mounted: function () {
    this.$nextTick(function () {
      // Run when mounted
      // Code that will run only after the
      // entire view has been rendered
      this.getState();
    });
  },
  watch: {
    //Watch for $route to be called and run getState
    $route: function () {
      this.getState();
    },
  },
  methods: {
    getState() {
      const loc = this.$route.params.path;
      var result = this.Locations.filter((items) => items.id === loc);
      this.res = result[0];
    },
  },
  metaInfo() {
    return {
      title: this.res.name,
      // all titles will be injected into this template
      titleTemplate: "%s | " + this.res.location,
      meta: [
        {
          vmid: "description",
          name: "description",
          content: "Enjoy the finest food in town surrounded by an upscale yet relaxed setting at " +
            this.res.name,
        },
      ],
      htmlAttrs: {
        lang: "en",
      },
    };
  },
};
</script>
<style>
.light {
  color: #fff !important;
}

.underline {
  text-decoration: underline;
}
</style>