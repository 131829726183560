import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Template from "../views/Template.vue";


Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    component: Home
},
{ path: '/menu/2020-Cocktail-Menu.pdf', redirect: '/menu/shelton-wine.pdf' },
{ path: '/menu/2020-Salad-Card.pdf', redirect: '/menu/shelton-salad-card.pdf' },
{ path: '/menu/2020-Shelton-Menu.pdf', redirect: '/menu/shelton-dinner.pdf' },
{
    path: "/:path",
    props: true,
    name: "Template",
    component: Template,
},
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    }
})

export default router