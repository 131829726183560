<template>
<div :class="[isActive ? 'header' : 'header mobile-nav-active']">
  <div class="mobile-nav-toggle d-lg-none" @click="toggleClass()">
      <b-icon icon="justify" class="rounded-circle bg-primary p-2" v-bind:class="[isActive ? 'mobile-nav-icon-active' : 'mobile-nav-icon-inactive']" variant="light"></b-icon>
      <b-icon icon="x" class="rounded-circle bg-primary p-2" v-bind:class="[isActive ? 'mobile-nav-icon-inactive' : 'mobile-nav-icon-active']" variant="light"></b-icon>
  </div> 
<header id="header"> 
    <div class="d-flex flex-column">
        <div class="profile">
            <router-link to="/"><img src="@/assets/img/parker-favicon.jpg" alt="parker. Restaurants Logo" title="parker. Restaurants" class="img-fluid rounded-circle"></router-link>
        </div>
        <div class="social-links">
            <a href="https://instagram.com/parker_eatery" target="_blank" title="parker. Restaurants Instagram" alt="parker. Restaurants Instagram" class="instagram"><b-icon-instagram></b-icon-instagram></a>
        </div>
        <nav class="nav-menu">
            <ul id="nav">
                <li v-for="item in navLinks" v-bind:key="item.name" @click="toggleClass()">
                <router-link :to="item.url"><b-icon :icon="item.icon"></b-icon>
                       <span>{{item.title}}</span></router-link>
                </li>
                <li>
                <a href="https://www.toasttab.com/locations/bbe4ee6e-ba12-4987-a358-9e0148a86409" target="_blank"><b-icon icon="laptop"></b-icon>
                       <span>Order Online</span></a>
                </li>
                <li>
                <a href="https://www.toasttab.com/parker-restaurants/giftcards" target="_blank"><b-icon icon="credit-card"></b-icon>
                       <span>Gift Cards</span></a>
                </li>
            </ul>
        </nav>
        <!-- .nav-menu -->
        <button type="button" class="mobile-nav-toggle d-xl-none"><i class="icofont-navigation-menu">
            </i></button>
    </div>
</header>
</div>
</template>

<script>
export default {
  name: 'Header',
  props: {
    msg: String
  },
data () {
    return {
            isActive: true,
            navLinks : [{
                "title":"Home",
                "url": "/",
                "icon" : "house-fill"
            }]
    }
  },
    mounted: function () {
    this.$nextTick(function () {
      this.isActive = !this.isActive;
    });
},
    methods: {
    toggleClass: function(){
      this.isActive = !this.isActive;
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
